/*
    Typography SCSS
*/

/* --- Headings --- */
h1, h2, h3, h4, h5 {
    &, .content & {
        padding: 0;
        margin:  0;
    }
}

/* --- Buttons & Links --- */
a, button {
}

/* --- Form --- */
label {
}

input:not([type=submit]) {
}

/* --- Table --- */
thead {
    td {
    }
}

tbody {
    td {
    }
}