/*
* Navigation Styles
*/
.navigation-menu {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  .menu .menu-link {
    margin-bottom: 0 !important;
  }
  .menu .menu-link.active, a.active {
    color: $secondary-color;
  }
  @include mq-min() {
    ul li:hover > ul {
      display:inherit;
    }

    ul ul li {
      width:240px;
      float:none;
      display:list-item;
      position: relative;
    }
  }
  @include mq-max() {
    [id^="drop"]:checked ~ ul {
      display: block;
    }
    [id^="drop"]:checked ~ .menu-link .toggle {
      transform: rotate(180deg);
    }
  }
}

/*
* Hamburger Menu on Navigation for mobile
*/

.hamburger {
  #menu-toggle {
    &:checked {
      ~ .menu-btn > span {
        transform: rotate(45deg);
      }
      ~ .menu-btn > span::before {
        top: 0;
        transform: rotate(0);
      }
      ~ .menu-btn > span::after {
        top: 0;
        transform: rotate(90deg);
      }
    }
  }

  .menu-btn {
    > span, > span::before, > span::after {
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: $white;
      transition-duration: .25s;
      border-radius: 4px;
    }
    > span::before {
      content: '';
      top: -8px;
    }
    > span::after {
      content: '';
      top: 8px;
    }
  }
}