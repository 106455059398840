.staff-component {
  .content-area {
    @include mq-max() {
      flex-wrap: wrap;
    }
  }
  .profile-img {
    max-width: 100px;
    @include mq-min() {
      width: 100px;
    }
    @include mq-max() {
      flex: 1 1 50%;
    }
  }
  .profile-details {
    h3 {
      margin-bottom: 0 !important;
    }
    @include mq-min() {
      min-width: 200px;
      width: 200px;
    }
    @include mq-max() {
      flex: 1 1 50%;
      margin-right: 0 !important;
    }
  }
  .profile-bio {
    @include mq-max() {
      margin-bottom: 1em;
    }
  }
}