/*
    Global Theme SCSS
*/

/* --- Screen Sizes --- */
$small-min-bp : 768px;
$small-max-bp : ($small-min-bp - 1);

/* --- Grid --- */
$total-cols: 12;

/* --- Transition --- */
$speed:  250ms;
$easing: ease-in-out;

/* --- Color Palette --- */
$primary-color:   #0E7053;
$secondary-color: #F1CB48;
$tertiary-color:  #66915A;
$link-color:       #222;

$primary-color-light:   lighten($primary-color, 8);

$secondary-color-light:    lighten($secondary-color, 8);
$secondary-color-lightest: lighten($secondary-color, 16);

/* --- Generic Colors --- */
$white: #FFF;
$black: #000;
$gray:  #ddd;

/* --- Links --- */
$link-color-hover: lighten($link-color, 10);

/* --- Bulma Overrides --- */
$primary: $primary-color;
$info: $secondary-color;
$link: $link-color;
$link-hover: $link-color-hover;

/* --- Typography --- */
$chivo:              Chivo,              sans-serif;
$chivo-italic:       Chivo-Italic,       sans-serif;
$chivo-light:        Chivo-light,        sans-serif;
$chivo-light-italic: Chivo-light-italic, sans-serif;
$chivo-bold:         Chivo-Bold,         sans-serif;
$chivo-bold-italic:  Chivo-Bold-Italic,  sans-serif;
$chivo-black:        Chivo-Black,        sans-serif;
$chivo-black-italic: Chivo-Black-Italic, sans-serif;

$overpass:              Overpass,              sans-serif;
$overpass-italic:       Overpass-Italic,       sans-serif;
$overpass-light:        Overpass-Light,        sans-serif;
$overpass-light-italic: Overpass-Light-Italic, sans-serif;
$overpass-bold:         Overpass-Bold,         sans-serif;
$overpass-bold-italic:  Overpass-Bold-Italic,  sans-serif;
$overpass-black:        Overpass-Black,        sans-serif;
$overpass-black-italic: Overpass-Black-Italic, sans-serif;
$overpass-thin:         Overpass-Thin,         sans-serif;
$overpass-thin-italic:  Overpass-Thin-Italic,  sans-serif;
