/*
  Modal Styles
*/

.modal {
  &-overlay {
    position: fixed;
    //top: 100px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    //body.has-navbar-fixed-bottom &, html.has-navbar-fixed-bottom & {
    //  bottom: 3.5rem;
    //}
    background-color: rgba($black, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
    
    z-index: 10;
  
    .close-modal {
      position: absolute;
      top: 15px;
      right: 15px;
      
      z-index: 10;
      
      &:after {
        content: "\2715";
      }
    }
    
    &-container {
      padding: 2.5rem;
      max-height: 85vh;
      background-color: rgba(255, 255, 255, 0.97);
      //border: 0.5px solid $black;
      border-radius: 4px;
      //overflow-y: auto;
      display: flex;
      justify-content: center;
      
      &-header {
        &-title {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          
          padding-bottom: 0.1em;
          margin: 0;
          
          //border-bottom: 1px solid $black;
          
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            
            font: 300 1.8rem 'americane-condensed', sans-serif;
            line-height: 1.15;
            letter-spacing: 0.5px;
            
            transition: transform 250ms cubic-bezier(.4,.25,.3,1);;
            
            &:hover, &:focus {
              transform: translateY(-5px);
              
            }
            
            img {
              margin-right: 0.4em;
            }
          }
        }
      }

      &-content {
        overflow: auto;
        padding: 2px;
      }

      &-modal-content {
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
    }
  
    &-container-small {
      @extend .modal-overlay-container;
    
      width: 35%;
    }
    
    &-container-half {
      @extend .modal-overlay-container;
  
      width: 50%;
    }
    
    &-container-full {
      @extend .modal-overlay-container;
  
      width: 95%;
    }

    &-container-full-on-mobile {
      @include mq-max() {
        width: 95%;
      }
    }
    &-container-half-on-mobile {
      @include mq-max() {
        width: 50%;
      }
    }
  }
}

/*
  Modal Animations
*/
@keyframes mmfadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes mmfadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes mmslideIn {
  from { transform: translateY(15%); }
  to { transform: translateY(0); }
}

@keyframes mmslideOut {
  from { transform: translateY(0); }
  to { transform: translateY(-10%); }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal-overlay {
  animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] [class*="modal-overlay-container-"] {
  animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal-overlay {
  animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] [class*="modal-overlay-container-"] {
  animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide [class*="modal-overlay-container-"],
.micromodal-slide .modal-overlay {
  will-change: transform;
}