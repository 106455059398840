.lf-form {
  .label.is-required {
    &:after {
      content: '*';
      color: $primary;
    }
  }
  &-terms-conditions{
    overflow-y: scroll;
    height: 10em;
    background-color: lightgray;
    max-width:22em;
    padding: 0.5em;
  }
}