/**
    Flexbox Bases Grid
    Example: https://codepen.io/vinsongrant/pen/YjyaEa
**/

.grid {
    &, .row {
        position: relative;
        width: 100%;
        @include mq-min() {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
        }
    }

    /**
        Modifiers
    **/
    &.align-center {
        justify-content: center;
        [class*="col-"] { margin-right: 0; }
    }
    &.align-left {
        justify-content: flex-start;
        [class*="col-"] { margin-right: 0; }
    }
    &.align-right {
        justify-content: flex-end;
        [class*="col-"] { margin-right: 0; }
    }
    &.no-wrap {
        flex-wrap: nowrap;
    }
    &.relaxed {
        @include mq-min() {
            [class*="col-"] {
                padding-left: 2%;
                padding-right: 2%;
            }
            &.super [class*="col-"] {
                padding-left: 4%;
                padding-right: 4%;
            }
            &.semi [class*="col-"] {
                padding-left: 1%;
                padding-right: 1%;
            }
        }
    }
    &.no-padding {
        [class*="col-"] {
            padding: 0;
        }
    }

    /**
        Columns
    **/
    [class*="col-"] {
        position: relative;
        margin-right: auto; // align flexbox items to the left after they wrap
        flex: 1;
    }
    @for $i from 1 through $total-cols {
        .col-#{$i} {
            width: decimal-floor(100% / $total-cols * $i);
            max-width: decimal-floor(100% / $total-cols * $i);
            flex-basis: decimal-floor(100% / $total-cols * $i);
            @include mq-max() {
                width: 100%;
                max-width: 100%;
            }
        }
    }

    /**
        Breakpoint Modifiers
    **/
    &.no-breakpoint {
        &, .row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
        }
        @for $i from 1 through $total-cols {
            .col-#{$i} {
                @include mq-max() {
                    width: decimal-floor(100% / $total-cols * $i);
                    max-width: decimal-floor(100% / $total-cols * $i);
                    flex-basis: decimal-floor(100% / $total-cols * $i);
                }
            }
        }
    }

} // .grid




/// Presentation styles only
//[class*="col-"] {
//    border: 2px solid #aaa;
//    background: #ccc;
//    p {
//        background: #eee;
//        padding: 0.5em;
//        font-size: 20px;
//        margin: 0;
//    }
//}