/**
    Media Queries SCSS
**/

@mixin mq-max($size: $small-max-bp) {
    @media (max-width: #{$size}) { @content; }
}

@mixin mq-min($size: $small-min-bp) {
    @media (min-width: #{$size}) { @content; }
}

@mixin mq-max-min($max: $small-max-bp, $min: $small-min-bp) {
    @media (max-width: #{$max}) and (min-width: #{$min}) { @content; }
}