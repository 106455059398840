/*
    Box Shadows SCSS
*/

@mixin box-shadow-top {
    box-shadow: 0 -15px 10px -15px rgba($black, 0.6);
}

@mixin box-shadow-bottom {
    box-shadow: 0 15px 10px -15px rgba($black, 0.6);
}

@mixin box-shadow-left {
    box-shadow: -15px 0 10px -15px rgba($black, 0.6);
}

@mixin box-shadow-right {
    box-shadow: 15px 0 10px -15px rgba($black, 0.6);
}