$allSizes: append($sizes, 1px);
$sides: 'top' 'bottom' 'left' 'right';
$spaceTypes: 'padding','margin';
@for $s from 1 through 4{
  $side: #{nth($sides,$s)};

  // .has-margin-{side}-size-{n}
  @for $i from 1 through 8 {
    @for $j from 1 through 2 {
      .has-#{nth($spaceTypes,$j)}-#{$side}-size-#{$i} {
        #{nth($spaceTypes,$j)}-#{$side}: nth($allSizes, $i) !important;
      }
    }
  }

  @for $j from 1 through 2 {
    .has-no-#{nth($spaceTypes,$j)}-#{$side} {
      #{nth($spaceTypes,$j)}-#{$side}: 0 !important;
    }
  }
  //// .has-no-margin-{side}
  //.has-no-margin-#{$side} {margin-#{$side}: 0 !important;}
  //.has-no-padding-#{$side} {padding-#{$side}: 0 !important;}
}
@for $j from 1 through 2 {
  .has-no-#{nth($spaceTypes,$j)}-x {
    #{nth($spaceTypes,$j)}-left: 0 !important;
    #{nth($spaceTypes,$j)}-right: 0 !important;
  }
  .has-no-#{nth($spaceTypes,$j)}-y {
    #{nth($spaceTypes,$j)}-top: 0 !important;
    #{nth($spaceTypes,$j)}-bottom: 0 !important;
  }
}
@for $i from 1 through 8 {
  @for $j from 1 through 2 {
    .has-#{nth($spaceTypes,$j)}-x-size-#{$i} {
      #{nth($spaceTypes,$j)}-left: nth($allSizes, $i) !important;
      #{nth($spaceTypes,$j)}-right: nth($allSizes, $i) !important;
    }
    .has-#{nth($spaceTypes,$j)}-y-size-#{$i} {
      #{nth($spaceTypes,$j)}-top: nth($allSizes, $i) !important;
      #{nth($spaceTypes,$j)}-bottom: nth($allSizes, $i) !important;
    }
  }
}

.is-clickable {
  cursor: pointer;
}
.is-flex-column {
  flex-flow: column;
}
.is-flex-space-between {
  justify-content: space-between !important;
}
.is-flex-wrap {
  flex-wrap: wrap;
}
.is-flexible-size {
  flex-grow: 1;
  flex-shrink: 1;
}
.is-flex-align-end {
  align-self: flex-end;
}
.is-fullwidth {
  width: 100%;
}
.is-fullheight {
  height: 100%;
}
.is-overflow-auto {
  overflow: auto !important;
}

.has-no-border-radius-left {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.has-no-border-radius-right {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.has-no-border-radius-top {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.has-no-border-radius-bottom {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}