/* Swiper Slider */
.slider {
  .swiper-pagination-bullets .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    display: inline-block;
    border-radius: 50%;
    background: transparent;
    border: 2px solid white;
    opacity: 1;
    &.swiper-pagination-bullet-active {
      background: white;
    }
    @include mq-min() {
      width: 20px;
      height: 20px;
      margin: 0 12px !important;
    }
    @include mq-max() {
      width: 10px;
      height: 10px;
      display: inline-block;
      border-radius: 50%;
      background: transparent;
      border: 1px solid #fff;
      opacity: 1;
      margin: 0 4px!important;
    }
  }
  .swiper-pagination {
    text-align: right;
    right: .5rem !important;
    bottom: 1rem !important;
    left: unset !important;
    @include mq-min() {
      right: 2.5rem !important;
      bottom: 2.5rem !important;
    }
  }
  .slide-title {
    margin-bottom: 0 !important;
  }
  .swiper-slide {
    width: 100% !important;
    height: auto;
  }
  .slide-mobile {
    position: relative;
    &:after {
      content:'';
      position:absolute;
      left:0; top:0;
      width:100%; height:100%;
      display:inline-block;
      //background: linear-gradient(to bottom, rgba(51,51,51,0) 0%, rgba(51,51,51,1) 100%);
    }
  }
  .mobile-content-holder {
    position: relative;
    @include mq-max() {
      h2:not(:first-child), h3:not(:first-child),
      h4:not(:first-child), h5:not(:first-child) {
        margin-top: 1rem !important;
      }
    }
    &:before {
      content: '';
      position: absolute;
      padding: 1em;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to top, rgba(51, 51, 51, 1) 100%, rgba(51, 51, 51, 0.1) 0%);
    }
  }
}