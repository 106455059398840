@layer components {
  body#page-content {
    @apply font-titillium;
  }
  .content-page-wrapper {
    @apply p-4 md:p-10 bg-white rounded border border-bank-base-mid border-opacity-20;
  }
  .container-bank-xl {
    max-width: 1440px;
  }
  .general-padding { @apply md:py-16 py-10 md:px-10 px-4 }
  /* Card UI */
  .page-content .card-title { @apply md:text-2xl text-xl font-bold leading-9 text-bank-dark }

  /* Typography */
  /*.content p,*/
  .card-content p { @apply font-normal leading-6 }
  .content a:not(.button) { @apply text-bank-dark-green; }
  .content blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre { margin: 0 !important; }
  .page-content h1, .page-content .links h2 { @apply font-bold text-bank-dark text-3.5xl leading-10 }
  .page-content h2 { @apply font-semibold text-bank-dark text-3xl leading-9 }
  .page-content h3 { @apply font-bold text-bank-dark-green text-xl leading-6 }
  .page-content h4 { @apply font-bold text-bank-dark leading-6 }
  /*.page-content h5 { @apply font-bold text-bank-dark leading-6 }*/
  /* Transitions */
  .default-transition {
    @apply transition duration-500 ease-in-out;
  }
  .slider-content p {
    @apply font-normal md:text-2.5xl text-white leading-6 md:leading-10;
  }
  .slider-content h2 {
    @apply md:text-5xl text-2xl leading-4 md:leading-15 font-bold text-white slide-title;
  }

  /* Buttons */
  .page-content .transparent-btn.small {
    @apply border-2 border-bank-light-green text-bank-light-green
    leading-4 py-1 px-4 hover:text-bank-mid-green hover:border-bank-mid-green
    }
  .button {
    @apply uppercase font-bold md:p-6 p-4 rounded border-0
    leading-4 text-white bg-bank-light-green hover:text-white hover:bg-bank-mid-green
    normal-case focus:text-white
    }
  .transparent-login-btn {
    @apply md:py-4 py-3 md:px-6 px-4 rounded border-2
    default-transition hover:border-bank-dark border-bank-base-mid border-opacity-40
    uppercase text-black font-bold leading-4 text-center tracking-wide w-full;
  }
  .transparent-sign-up-btn {
    @apply md:py-4 py-3 md:px-6 px-4 rounded border-2 border-bank-mid-green
    uppercase text-bank-mid-green hover:text-bank-dark-green hover:border-bank-dark-green
    font-bold leading-4 text-center tracking-wide w-full;
  }

  /* Components */
  .content-page-wrapper .has-top-border[id^='component_']:not(:first-child)
  {
    @apply pt-10 rounded border-t-2 border-bank-base-mid border-opacity-20;
  }

  /*  Overrides  */
  .table thead th {
    color: white !important;
  }
}